<script setup lang="ts">
import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'radix-vue'
import { exchangePrice, price } from '~/lib/price'

const props = withDefaults(defineProps<{
  minValue?: number
  maxValue?: number
  minRange?: number
  maxRange?: number
}>(), {
  minValue: 0,
  maxValue: 300,
  minRange: 0,
  maxRange: 300,
})
const emit = defineEmits<{
  (e: 'apply', value: number[]): void
}>()

const storefrontStore = useStorefrontStore()
const { currency } = storeToRefs(storefrontStore)

const sliderValue = ref<[number, number]>([props.minValue, props.maxValue])

watchEffect(() => {
  sliderValue.value = [props.minValue, props.maxValue]
})

function onApply(e: number[]) {
  emit('apply', e)
}

const minValueSlider = computed(() => {
  return sliderValue.value[0]
})

const maxValueSlider = computed(() => {
  return sliderValue.value[1] > props.maxRange ? props.maxRange : sliderValue.value[1]
})

const minValueFormatted = computed(() => {
  const exchangedPrice = exchangePrice(minValueSlider.value, currency.value.code, { pretty: true })
  return price(Math.round(exchangedPrice), undefined)
})

const maxValueFormatted = computed(() => {
  const exchangedPrice = exchangePrice(maxValueSlider.value, currency.value.code, { pretty: true })
  return price(Math.round(exchangedPrice), undefined)
})
const minThumbRef = ref<HTMLDivElement | null>(null)
const maxThumbRef = ref<HTMLDivElement | null>(null)
const minLabelRef = ref<HTMLSpanElement | null>(null)
const maxLabelRef = ref<HTMLSpanElement | null>(null)
const sliderRef = ref<HTMLDivElement | null>(null)

interface boundingClientRect {
  x: number
  width: number
}
interface labelsPosition {
  min: string
  max: string
}

function updatedLabelsPosition() {
  const labelsPosition: labelsPosition = {}
  const sliderRefBoundingRect: boundingClientRect = sliderRef.value?.getBoundingClientRect() || { x: 0, width: 0 }

  const minThumbBoundingRect: boundingClientRect = minThumbRef.value?.getBoundingClientRect() || { x: 0, width: 0 }
  const maxThumbBoundingRect: boundingClientRect = maxThumbRef.value?.getBoundingClientRect() || { x: 0, width: 0 }

  const minLabelBoundingRect: boundingClientRect = minLabelRef.value?.getBoundingClientRect() || { x: 0, width: 0 }
  const maxLabelBoundingRect: boundingClientRect = maxLabelRef.value?.getBoundingClientRect() || { x: 0, width: 0 }

  // Align min label right if possible
  labelsPosition.min = (minThumbBoundingRect.x - minThumbBoundingRect.width) < sliderRefBoundingRect.x
    ? `${sliderRefBoundingRect.x - minThumbBoundingRect.x}px`
    : `${-minLabelBoundingRect.width + (minThumbBoundingRect.width * 0.5)}px`

  // Align max label left if possible
  labelsPosition.max = (maxThumbBoundingRect.x + maxLabelBoundingRect.width) > sliderRefBoundingRect.width
    ? `${(-sliderRefBoundingRect.width + maxThumbBoundingRect.x)}px`
    : `${-maxLabelBoundingRect.width + (maxThumbBoundingRect.width * 0.5)}px`

  // Check if min label overlaps with max label
  if (minThumbBoundingRect.x > sliderRefBoundingRect.width - maxLabelBoundingRect.width)
    labelsPosition.min = `${sliderRefBoundingRect.width - maxLabelBoundingRect.width - minThumbBoundingRect.x - minLabelBoundingRect.width}px`

  // Check if max label overlaps with min label
  if (maxThumbBoundingRect.x < sliderRefBoundingRect.x + minLabelBoundingRect.width)
    labelsPosition.max = `${((sliderRefBoundingRect.x + minLabelBoundingRect.width) - maxThumbBoundingRect.x + maxLabelBoundingRect.width) * -1}px`

  // Reset labels positions
  if (minValueSlider.value === 0)
    labelsPosition.min = '0px'

  if (maxValueSlider.value === props.maxRange)
    labelsPosition.max = '0px'

  return labelsPosition
}
</script>

<template>
  <div
    ref="sliderRef"
    class="range-slider"
    :aria-label="$t('rangeSlider')"
  >
    <SliderRoot

      v-model="sliderValue"
      :show-tooltip="true"
      class="slider-root"
      :step="props.maxValue > 100 ? 1 : 0.1"
      :default-value="[minValue, maxValue]"

      :min-steps-between-thumbs="1"
      :min="minRange"
      :max="maxRange"
      @value-commit="onApply"
    >
      <SliderTrack class="slider-track">
        <SliderRange class="slider-range" />
      </SliderTrack>
      <SliderThumb
        class="slider-thumb"
        :aria-label="minValueFormatted"
      >
        <div ref="minThumbRef">
          <span
            ref="minLabelRef"
            class="min"
            :style="{ left: updatedLabelsPosition().min }"
          >
            {{ minValueFormatted }}
          </span>
        </div>
      </SliderThumb>
      <SliderThumb
        class="slider-thumb"
        :aria-label="maxValueFormatted"
      >
        <div ref="maxThumbRef">
          <span
            ref="maxLabelRef"
            class="max"
            :style="{ right: updatedLabelsPosition().max }"
          >
            {{ maxValueFormatted }}
          </span>
        </div>
      </sliderthumb>
    </SliderRoot>
  </div>
  <section
    class="labels"
    aria-hidden="true"
  />
</template>

  <style lang="scss" scoped>
.range-slider {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-block: 1.8rem;
  padding-left: 0.8rem;
  position: relative;
  user-select: none;
  touch-action: none;
}

.slider-track {
  display: block;
  width: 100%;
  height: 0.4rem;
  border-radius: 0.2rem;
  margin: 0 auto;
  background-color: var(--gray-dark);
}

.slider-range {
  position: absolute;
  background-color: var(--pink);
  border-radius: 1.2rem;
  height: 0.4rem;
}

.slider-thumb {
  position: relative;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background-color: var(--black);
  border-radius: 50%;
  bottom: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-hover);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);
  }

  span {
    // transition: all 0.2s;
    position: relative;
    top: 42px;
    white-space: nowrap;

    &.min {
      float: left;
      text-align: left;}
    &.max {
      float: right;
      text-align: right;
    }
  }
}

.labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.2rem 0 2.4rem 0;
}
</style>
