<script setup lang="ts">
import type { PreviousInteraction } from 'types/newsletter'
import { wait } from 'lib/helpers/wait'

const modalStore = useModalStore()
const storyblokStore = useStoryblokStore()
const { state: storyblok } = storeToRefs(storyblokStore)
const closing = ref(false)

async function closePopup() {
  closing.value = true
  try {
    const prevInteraction = localStorage.getItem('newsletterInteraction')
    const interaction: PreviousInteraction = JSON.parse(prevInteraction || '')
    if (interaction.type !== 'registered')
      throw new EvalError('not registered')
  }
  catch (_) {
    localStorage.setItem(
      'newsletterInteraction',
      JSON.stringify({ type: 'close', time: new Date() }),
    )
  }
  await wait(600)
  modalStore.showNewsletter = false
}
</script>

<template>
  <aside
    class="newsletter-popup"
    :class="[{ closing }]"
    aria-modal="true"
  >
    <ButtonBasic
      :aria-label="$t('closeProductView')"
      class="close"
      size="small"
      design="no-border"
      icon-left="close"
      background-color="transparent"
      data-test="newsletter-close"
      @click="closePopup"
    />
    <Newsletter
      v-if="storyblok.newsletter"
      :newsletter="storyblok.newsletter"
      context="popup"
      background-color="var(--pink)"
      @accept="closePopup"
    />
  </aside>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
.newsletter-popup {
  left: 5%;
  bottom: 2rem;
  z-index: 101;
  width: 90%;
  position: fixed;
  overflow: hidden;
  padding: 2.4rem 2.4rem 3.2rem;
  animation: slideIn 0.6s ease-in forwards;
  animation-delay: 1s;
  background: var(--pink);
  transform: translate(-110%, 0%);
}

.closing {
  animation: slideOut 0.6s ease-out forwards;
}

button.close {
  top: 0;
  right: 0;
  position: absolute;
  padding: 1.5rem 1.5rem 3rem 3rem;
  cursor: pointer;
}

@media (min-width: $large-breakpoint) {
  .newsletter-popup {
    width: 46.2rem;
    left: 2.4rem;
    bottom: 2.4rem;
    padding: 2.8rem 4.8rem 3.2rem;
  }

  .block {
    width: 100%;
  }
}

@keyframes slideIn {
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes slideOut {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(-110%, 0%);
  }
}
</style>
