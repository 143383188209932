// original source code: https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
type CallbackFunction = (e: MouseEvent | KeyboardEvent) => void

export function useFocusTrap(el: Ref<HTMLElement | null>, close: CallbackFunction) {
  const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  let firstFocusableElement: HTMLElement | null = null
  let lastFocusableElement: HTMLElement | null = null

  function trapFocus(e: KeyboardEvent) {
    const isTabPressed = e.key === 'Tab'
    const isEscapePressed = e.key === 'Escape'
    if (isEscapePressed) {
      close(e)
      return
    }
    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement?.focus() // add focus for the last focusable element
        e.preventDefault()
      }
    }
    else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement?.focus() // add focus for the first focusable element
        e.preventDefault()
      }
    }
  }

  onMounted(() => {
    const modal = el.value
    const focusableContent = modal?.querySelectorAll(focusableElements)

    firstFocusableElement = focusableContent?.[0] as HTMLElement// get the first element to be focused inside modal
    lastFocusableElement = focusableContent?.[focusableContent.length - 1] as HTMLElement // get the last element to be focused inside the modal
    firstFocusableElement?.focus() // add focus for the first focusable element

    if (!modal)
      return
    document.addEventListener('keydown', trapFocus)
  })

  onUnmounted(() => {
    document.removeEventListener('keydown', trapFocus)
  })
}
