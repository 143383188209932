<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core'
import { wait } from 'lib/helpers/wait'

type Sides = 'right' | 'left' | 'top' | 'bottom' | 'none'
type Alignment = 'start' | 'center' | 'end'

interface SlideFromProp {
  mobile: Sides
  desktop: Sides
}

interface AlignToProp {
  mobile: Alignment
  desktop: Alignment
}

interface Props {
  show: boolean
  slideFrom?: SlideFromProp
  alignTo?: AlignToProp
  clickAway?: boolean
  noAnimation?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  slideFrom: () => ({ mobile: 'left', desktop: 'left' }),
  alignTo: () => ({ mobile: 'center', desktop: 'center' }),
  clickAway: true,
  noAnimation: false,
  quickShopState: undefined,
})

const modalStore = useModalStore()
const modalWrapper = ref<HTMLDivElement>()
const slotWrapper = ref<HTMLDivElement>()
const isOpen = ref(false)
const { blockModals } = storeToRefs(modalStore)
const isSmallScreen = useMediaQuery('(max-width: 479px)')
const quickShopStatus = useQuickShopStatus()

function showModal() {
  isOpen.value = true
  slotWrapper.value?.focus()
  blockModals.value = true
  document.body?.classList.add('lock-scroll')
}

async function hideModal() {
  modalWrapper.value?.classList.add('fade-background')
  slotWrapper.value?.classList.add('hiding-slot')
  !props.noAnimation && (await wait(300))
  isOpen.value = false
  blockModals.value = false
  if (!(quickShopStatus.value === 'visible' && isSmallScreen.value))
    document.body?.classList.remove('lock-scroll')
}

if (props.show)
  showModal()

watch(
  () => props.show,
  () => {
    if (props.show) {
      showModal()
      return
    }
    if (!props.show)
      hideModal()
  },
)
</script>

<template>
  <div
    v-if="isOpen"
    ref="modalWrapper"
    name="modal"
    role="dialog"
    aria-modal="true"
    class="modal-wrapper"
    :class="[
      `from-${slideFrom.mobile}-mobile`,
      `from-${slideFrom.desktop}-desktop`,
      `align-${alignTo.mobile}-mobile`,
      `align-${alignTo.desktop}-desktop`,
    ]"
    @click="
      e => {
        !clickAway && e.stopPropagation()
      }
    "
  >
    <div
      ref="slotWrapper"
      tabindex="0"
      class="slot-wrapper"
      :class="{ 'no-animation': noAnimation }"
    >
      <slot name="modal-content" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

$animation-duration: 0.3s;

.slot-wrapper {
  display: flex;
  position: relative;
  overflow-y: hidden;
  height: 100%;
}

.no-animation {
  animation: none !important;
  transform: none !important;
}

.modal-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-basis: auto;
  flex-direction: column;
  will-change: background-color;
  background-color: var(--overlay-in-bg);
  animation: fade-in $animation-duration ease forwards;
  backdrop-filter: blur(1.5rem);
  // Must be that high to override the external styles of the chatbot
  z-index: 6000;

  &.fade-background {
    animation: fade-out $animation-duration ease forwards;
  }
}

@media (max-width: $tablet) {
  .modal-wrapper {
    &.from-none-mobile {
      display: none;
    }

    &.from-top-mobile,
    &.from-bottom-mobile {
      &.align-start-mobile .slot-wrapper {
        justify-content: flex-start;
      }

      &.align-center-mobile .slot-wrapper {
        justify-content: center;
      }

      &.align-end-mobile .slot-wrapper {
        justify-content: flex-end;
      }
    }

    &.from-bottom-mobile {
      justify-content: flex-end;

      .slot-wrapper {
        transform: translateY(100%);
        animation: slideInBottom $animation-duration ease-in forwards;

        &.hiding-slot {
          animation: slideOutBottom $animation-duration ease-out forwards;
        }
      }
    }

    &.from-top-mobile {
      justify-content: flex-start;

      .slot-wrapper {
        transform: translateY(-100%);
        animation: slideInTop $animation-duration ease-in forwards;

        &.hiding-slot {
          animation: slideOutTop $animation-duration ease-out forwards;
        }
      }
    }

    &.from-left-mobile .slot-wrapper {
      justify-content: flex-start;
      transform: translateX(-100%);
      animation: slideInLeft $animation-duration linear forwards;

      &.hiding-slot {
        animation: slideOutLeft $animation-duration linear forwards;
      }
    }

    &.from-right-mobile .slot-wrapper {
      justify-content: flex-end;
      transform: translateX(100%);
      animation: slideInRight $animation-duration ease-in forwards;

      &.hiding-slot {
        animation: slideOutRight $animation-duration ease-out forwards;
      }
    }

    &.from-left-mobile,
    &.from-right-mobile {
      &.align-start-mobile {
        justify-content: flex-start;
      }

      &.align-center-mobile {
        justify-content: center;
      }

      &.align-end-mobile {
        justify-content: flex-end;
      }
    }
  }
}

@media (min-width: $tablet) {
  .modal-wrapper {
    &.from-none-desktop {
      display: none;
    }

    &.from-top-desktop,
    &.from-bottom-desktop {
      &.align-start-desktop .slot-wrapper {
        justify-content: flex-start;
      }

      &.align-center-desktop .slot-wrapper {
        justify-content: center;
      }

      &.align-end-desktop .slot-wrapper {
        justify-content: flex-end;
      }
    }

    &.from-bottom-desktop {
      justify-content: flex-end;

      .slot-wrapper {
        transform: translateY(100%);
        animation: slideInBottom $animation-duration ease-in forwards;

        &.hiding-slot {
          animation: slideOutBottom $animation-duration ease-out forwards;
        }
      }
    }

    &.from-top-desktop {
      justify-content: flex-start;

      .slot-wrapper {
        transform: translateY(-100%);
        animation: slideInTop $animation-duration ease-in forwards;

        &.hiding-slot {
          animation: slideOutTop $animation-duration ease-out forwards;
        }
      }
    }

    &.from-left-desktop .slot-wrapper {
      justify-content: flex-start;
      transform: translateX(-100%);
      animation: slideInLeft $animation-duration linear forwards;

      &.hiding-slot {
        animation: slideOutLeft $animation-duration linear forwards;
      }
    }

    &.from-right-desktop .slot-wrapper {
      justify-content: flex-end;
      transform: translateX(100%);
      animation: slideInRight $animation-duration ease-in forwards;

      &.hiding-slot {
        animation: slideOutRight $animation-duration ease-out forwards;
      }
    }

    &.from-left-desktop,
    &.from-right-desktop {
      &.align-start-desktop {
        justify-content: flex-start;
      }

      &.align-center-desktop {
        justify-content: center;
      }

      &.align-end-desktop {
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: $phone) {
  .slot-wrapper {
    overflow-y: visible;
    height: auto;
  }
}

@keyframes slideInBottom {
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideOutBottom {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes slideInLeft {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slideInTop {
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideOutTop {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes fade-in {
  0% {
    background-color: var(--overlay-out-bg);
  }

  100% {
    background-color: var(--overlay-in-bg);
  }
}

@keyframes fade-out {
  0% {
    background-color: var(--overlay-in-bg);
  }

  100% {
    background-color: var(--overlay-out-bg);
  }
}
</style>
