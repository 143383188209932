<script setup lang="ts">
const emit = defineEmits(['close'])
const modalStore = useModalStore()
const { showCountrySwitcher } = storeToRefs(modalStore)
const el = ref(null)

function closeModal(e: MouseEvent) {
  if (
    e.target instanceof HTMLElement
    && (e.target.className.includes('slot-wrapper') || e.target.className.includes('container'))
  ) {
    modalStore.showCountrySwitcher = false
  }
}

onClickOutside(el, closeModal)

function select() {
  modalStore.showCountrySwitcher = false
  emit('close')
}
</script>

<template>
  <ModalWrapper
    class="modal"
    :show="showCountrySwitcher"
    :slide-from="{ mobile: 'bottom', desktop: 'bottom' }"
    :align-to="{ mobile: 'center', desktop: 'center' }"
    :disable-header="false"
    :click-away="true"
    no-animation
    header-size="no-header"
  >
    <template #modal-content>
      <section class="container">
        <NavigationCountrySwitcher
          ref="el"
          @select="select"
        />
      </section>
    </template>
  </ModalWrapper>
</template>

<style lang="scss" scoped>
@import '~/assets/scss/rules/breakpoints';

.modal {
  z-index: 1000;
}

.container {
  height: 100vh;
  height: calc(var(--window-height));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

@media (min-width: $phone) {
  .container {
    align-items: center;
  }
}
</style>
